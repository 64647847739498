<template>
  <spinner :visible="spinnerVisible"></spinner>
  <transition name="fade" appear>
  <div v-if="ready" class="list" >
    <div v-if="!isMobile" :class="{overlayer: isOverlay}" @click="hideOverlay"></div>

    <navbar :class="{ show: isActive }" @clickOnShowAllHotelsParent="resetFilters"></navbar>
    <filters
      class="filters"
      @activeFilter="setOverlay"
      @filtersChange="handleFilters"
      @overlay="setOverlay"
      @filterDate="dateFilterHotels"
      @restoreDate="dateFilterHotels"
      :filteredHotels="filteredHotels"
      :resetAllFilters="resetAllFilters"
      :deselectAllFilters="deselectAllFilters"
    ></filters>

    <mobile-navbar
      class="mobile-filters"
      @activeFilter="setOverlay"
      @filtersChange="handleFilters"
      @sortChange="handleSort"
      @filterDate="dateFilterHotels"
      @restoreDate="dateFilterHotels"
      :filteredHotels="filteredHotels"
      :resetAllFilters="resetAllFilters"
      :deselectAllFilters="deselectAllFilters"
    ></mobile-navbar>

    <div class="content" :class="{mapview: isMapView, 'mobile-map-view': mobileMapViewActivated}">
      <transition name="slide-in" appear>
      <div class="list-container">
        <sorter @sortChange="handleSort" :filteredHotels="filteredHotels" :isMapView="isMapView" v-if="filteredHotels.length" />
        <ul v-if="filteredHotels.length && site" ref="hotelList">
          <li v-for="hotel in sortedHotels.slice(0, (pageNumber))" :key="hotel.hotelId" >
            <hotel-card-small :mapView="true" v-if="isMapView" :hotel="hotel" ></hotel-card-small>
            <slot v-if="sortedHotels.indexOf(hotel) === (2) && !isMapView">
              <hotel-card
                v-if="!isMapView"
                @hoveredHotel="setHoveredHotel"
                :startDate="filters.startDate"
                :finishDate="filters.finishDate"
                :hotel="hotel"
                class="hotel-card">
              </hotel-card>
              <book-with-us />
            </slot>
            <slot v-else-if="sortedHotels.indexOf(hotel) === (pageNumber - 1)" >
              <hotel-card v-if="!isMapView" :cityCenterLat="cityCenterLat" :cityCenterLng="cityCenterLng" :hotel="hotel"></hotel-card>
              <a class="show-more" v-if="!isMapView" @click="showMore" >{{ $t('showMore') }}</a>
            </slot>
            <slot v-else >
              <hotel-card
                v-if="!isMapView"
                @hoveredHotel="setHoveredHotel"
                :startDate="filters.startDate"
                :finishDate="filters.finishDate"
                :class="{'first-card': sortedHotels.indexOf(hotel) === 0 }"
                :hotel="hotel">
              </hotel-card>
            </slot>
          </li>
        </ul>
        <div v-else class="no-result">
          <div>
            <img src="@/assets/list-page/ic_no_results.svg" alt="">
            <p>{{ $t('noResults') }}</p>
            <a v-on:click="resetFilters">{{ $t('restoreFilters') }}</a>
          </div>
        </div>
      </div>
      </transition>
      <hotel-map
        :hotels="filteredHotels"
        :isMapView="isMapView"
        :initialZoom="12"
        :hoveredHotel="hoveredHotel"
        :mobileMapViewActivated="mobileMapViewActivated"
        :center="{
          lat: cityCenterLat,
          lng: cityCenterLng
        }"
      ></hotel-map>
      <div class="mobile-map-buttons">
        <button class="toggle-mobile-map" @click="toggleMobileMapView"><img :src="mobileMapToggleImage" alt=""></button>
      </div>
    </div>
    <ContactFooter :showFooter="true" :isMobile="isMobile"/>
    <legal-footer :showFooter="true" :congressName="congressName"/>
  </div>
  </transition>
</template>

<script>
import TitleSetter from '@/components/TitleSetter'
import Navbar from '@/components/Navbar'
import Filters from '@/components/list-page/Filters'
import MobileNavbar from '@/components/list-page/MobileNavbar'
import Sorter from '@/components/list-page/Sorter'
import HotelCard from '@/components/list-page/HotelListCard'
import HotelCardSmall from '@/components/list-page/HotelCardSmall'
import BookWithUs from '@/components/list-page//BookWithUs'
import HotelMap from '@/components/list-page/HotelMap'
import ContactFooter from '@/components/landing-page/ContactFooter'
import LegalFooter from '@/components/landing-page/LegalFooter'
import Spinner from "@/components/Spinner";
import siteService from "@/services/siteService";
import { formatDate, getDayOfWeek } from '@/helpers/utils'
import {
  filterByStars,
  filterByPrice,
  filterByName,
  filterByDistanceFrom,
  filterByRoomType,
  filterByWalkingDistance,
  filterByEcoFriendly,
  filterByCityCenter,
  filterByRecommended
} from '@/helpers/filters.js'
import { mapGetters } from 'vuex'

export default {
  name: 'list',
  components: {
    TitleSetter,
    Navbar,
    Filters,
    Sorter,
    HotelCard,
    HotelCardSmall,
    BookWithUs,
    HotelMap,
    ContactFooter,
    LegalFooter,
    MobileNavbar,
    Spinner,
  },
  data: () => {
    return {
      isMobile: false,
      hoveredHotel: null,
      isOverlay: false,
      isMapView: false,
      pageNumber: 20,
      numberOfFilteredHotels: null,
      showingAllHotels: false,
      isActive: true,
      deselectAllFilters: false,
      resetAllFilters: false,
      filters: {
        isCityCenter: false,
        isWalkingDistance: false,
        isRecommended: false,
        isEcoFriendly: false,
        startDate: '',
        finishDate: '',
        rooms: '',
        stars: [],
        distanceFromLat: 0,
        distanceFromLng: 0,
        distanceInKm: '',
        startPrice: '',
        endPrice: '',
        hotelName: '',
        roomType: [],
        ammenities: [],
        neighborhoods: []
      },
      isSorting: false,
      sortOptions: {
        lowestStar: false,
        highestStar: false,
        lowestPrice: false,
        highestPrice: false,
        distanceToCongress: false,
        distanceToCity: false,
        popular: true,
        tripAdvisorRating: false
      },
      ready: false,
      mobileMapViewActivated: false,
      prevRoute: '/',
      spinnerVisible: false,
    }
  },
  methods: {
    formatDate: formatDate,
    setHoveredHotel (hotel) {
      this.hoveredHotel = hotel
    },
    handleScreeResize () {
      if (window.innerWidth <= 768) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
    resetFilters () {
      this.resetAllFilters = true
    },
    handleFilters (e) {
      this.filters[e.name] = e.value
      this.$store.dispatch('addFilters', e)
    },

    handleSort (e) {
      Object.keys(this.sortOptions).forEach(key => {
        this.sortOptions[key] = false;
      })
      this.sortOptions[e.value] = true
    },

    showMore () {
      this.pageNumber += 20
      if (this.pageNumber >= this.numberOfFilteredHotels) {
        this.showingAllHotels = true
      }
    },
    setOverlay (e) {
      if (e.value) {
        this.isOverlay = true
        document.body.style.overflow = 'hidden'
      } else {
        this.isOverlay = false
        document.body.style.overflow = 'visible'
      }
    },
    hideOverlay () {
      this.isOverlay = false
      document.body.style.overflow = 'visible'
      this.deselectAllFilters = true
    },
    toggleMobileMapView () {
      this.mobileMapViewActivated = !this.mobileMapViewActivated
    },
    setlocalStorageDates(currentStartDate, currentEndDate) {
      localStorage.setItem("currentStartDate", currentStartDate)
      localStorage.setItem("currentEndDate", currentEndDate)
    },
    dateFilterHotels(ev) {
      let currentStartDate = this.formatDate(ev.startDate).split('-').reverse().join('-')
      let currentEndDate = this.formatDate(ev.endDate).split('-').reverse().join('-')
      this.getHotels(currentStartDate, currentEndDate)
    },
    getHotels(start, end) {
      this.spinnerVisible = true
      return siteService.getSiteHotels(
          start,
          end
      )
      .then(data => {
        this.$store.commit('SET_HOTELS', data)
      })
      .catch(() => {
        this.$notify({
          type: "error",
          title: "Error",
          text: this.$i18n.t('errorFilteringHotelDate')
        });
      })
      .finally(() => {
        this.spinnerVisible = false
        this.setlocalStorageDates(start, end)
      })
    },
  },
  computed: {
    ...mapGetters([
      'site',
      'hotels',
      'congressName',
      'cityCenterLat',
      'cityCenterLng',
      'congressLat',
      'congressLng',
      'dataHotelsIsReady',
    ]),
    filteredHotels () {
      const { filters } = this
      let filtered = this.hotels

      if (filters.isWalkingDistance) filtered = filterByWalkingDistance(filtered)
      if (filters.isEcoFriendly) filtered = filterByEcoFriendly(filtered)
      if (filters.isCityCenter) filtered = filterByCityCenter(filtered)
      if (filters.isRecommended) filtered = filterByRecommended(filtered)
      if (filters.stars.length) filtered = filterByStars(filtered, filters.stars)
      if (filters.startPrice && filters.endPrice) filtered = filterByPrice(filtered, filters.startPrice, filters.endPrice)
      if (filters.hotelName) filtered = filterByName(filtered, filters.hotelName)
      if (filters.distanceFromType && filters.distanceInKm) filtered = filterByDistanceFrom(filtered, filters.distanceInKm, filters.distanceFromLat, filters.distanceFromLng, filters.distanceFromType)
      if (filters.roomType.length) filtered = filterByRoomType(filtered, filters.roomType)
      this.$store.commit("SET_FILTERED_HOTELS", filtered)
      return filtered
    },
    sortedHotels () {
      let hotelsToSort = this.filteredHotels.slice()

      // Sort by highest price
      if (this.sortOptions.highestPrice) {
        return hotelsToSort.sort((a, b) => {
          return b.average_day_price - a.average_day_price
        })
      }

      // Sort by lowest price
      if (this.sortOptions.lowestPrice) {
        return hotelsToSort.sort((a, b) => {
          return a.average_day_price - b.average_day_price
        })
      }

      // Sort by highest star
      if (this.sortOptions.highestStar) {
        return hotelsToSort.sort((a, b) => {
          return b.stars - a.stars
        })
      }

      // Sort by lowest star
      if (this.sortOptions.lowestStar) {
        return hotelsToSort.sort((a, b) => {
          return a.stars - b.stars
        })
      }

      // Sort by distance to Congress
      if (this.sortOptions.distanceToCongress) {
        return hotelsToSort.sort((a, b) => {
          return a.km_to_congress - b.km_to_congress
        })
      }
      // Sort by distance to City Center
      if (this.sortOptions.distanceToCity) {
        return hotelsToSort.sort((a, b) => {
          return a.km_to_center - b.km_to_center
        })
      }
      // Sort by popular (recommended check in back)
      if (this.sortOptions.popular) {
        return hotelsToSort.sort((a, b) => {
          return b.is_recommended - a.is_recommended
        })
      }
      return hotelsToSort.sort((a, b) => {
          return a.km_to_congress - b.km_to_congress
        })
    },
    mobileMapToggleImage () {
      return this.mobileMapViewActivated ? require('@/assets/maps/ic_mobile_list.svg') : require('@/assets/maps/ic_mobile_map.svg')
    }
  },

  watch: {
    dataHotelsIsReady(newValue) {
      if(newValue) {
        this.spinnerVisible = false
      }
    },
    '$route': function (to, _from) {
      if (to.query.mapview) {
        this.isMapView = true
      } else if (!to.query.mapview) {
        this.isMapView = false
      }

      if (to.query.walking) {
        this.filters.isWalkingDistance = true
      } else if (!to.query.walking) {
        this.filters.isWalkingDistance = false
      }

      if (to.query.ecoFriendly) {
        this.filters.isEcoFriendly = true
      } else if (!to.query.ecoFriendly) {
        this.filters.isEcoFriendly = false
      }

      if (to.query.city) {
        this.filters.isCityCenter = true
      } else if (!to.query.city) {
        this.filters.isCityCenter = false
      }

      if (to.query.recommended) {
        this.filters.isRecommended = true
      } else if (!to.query.city) {
        this.filters.isRecommended = false
      }
    }
  },
  beforeRouteEnter(_to, from, next) {
    next(vm => {
      vm.prevRoute = from['path']
      vm.mobileMapViewActivated = from.params.map
    })
    
  },
  mounted () {
    if(!this.dataHotelsIsReady) {
        this.spinnerVisible = true
    }
    this.$store.commit("SET_PREV_URL", this.prevRoute)
    if(this.prevRoute.startsWith('/hotel')) {
      this.filters = this.$store.getters.filters
    } else {
        this.resetFilters()
        this.resetAllFilters = false
    }
    if (!localStorage.getItem("currentStartDate") || !localStorage.getItem("currentEndDate")){
      this.setlocalStorageDates(this.site.start_at, this.site.finish_at)
    }
    if (this.$route.query.mapview) this.isMapView = true
    if (this.$route.query.walking) this.filters.isWalkingDistance = true
    if (this.$route.query.ecoFriendly) this.filters.isEcoFriendly = true
    if (this.$route.query.city) this.filters.isCityCenter = true
    if (this.$route.query.recommended) {
      this.filters.isRecommended = true
    } else {
      this.filters.isRecommended = false
    }

    if (window.innerWidth <= 768) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleScreeResize)
    })
  },
  unmounted () {
    window.removeEventListener('resize', this.handleScreeResize)
  },

  created () {
    this.$store.watch((_state) => {
      const dataIsReady = this.$store.getters.dataIsReady
      if (dataIsReady) {
        this.ready = true
        this.numberOfFilteredHotels = this.filteredHotels.length
        if (this.pageNumber > this.numberOfFilteredHotels) {
          this.showingAllHotels = true
        } else {
          this.showingAllHotels = false
        }
      }
    })
  },
  updated () {
    if (this.resetAllFilters) {
      this.resetAllFilters = false
    }
    if (this.deselectAllFilters) {
      this.deselectAllFilters = false
    }
    if(this.sortedHotels.length) {
      this.$refs.hotelList.scrollTop = 0
    }
  }
}
</script>

<style lang="scss" scoped>

//hide scrollbar
::-webkit-scrollbar {
display: none;
}

.hotel-card.first-card {
  margin-top: 10px;
}

.show-more {
  margin-top: 30px;
  display: block;
  background: #F8F8F8;
  border-radius: 5px;
  font-weight: 700;
  font-size: 15px;
  color: $secondary-black;
  letter-spacing: 0;
  line-height: 18.2px;
  height: 40px;
  padding-top: 10px;
  margin-right: 20px;
}

.show-more:hover {
  border: 1px solid $secondary-black;
}

.hotel-card {
  margin-top: 30px;
  margin-right: 20px;
}

.hotel-card:hover {
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.25);
}

.list {
  margin-top: 40px;
  position: relative;
}
ul {
  list-style: none;
  margin: 0;
  height:  calc(100vh - 240px) !important;
  overflow: scroll;
  padding-left: 20px;
  padding-top: 0px;
}

.content {
  display: grid;
  grid-template-columns: 60% 40%;
  height: calc(100vh - 160px);
  grid-template-areas:
  "hotel-list map"

}

.content.mapview {
  grid-template-columns: 20% 80%;
}
// .list-container, .vue-map-container {
//   width: 50% !important;
// }

.list-container {
  grid-area: hotel-list;
}

.no-result {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F8F8F8;
  border-radius: 5px;

  p {
    font-size: 28px;
    color: $secondary-black;
    font-family: "Frank Ruhl Libre", sans-serif;
    margin-bottom: 20px;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
    font-size: 18px;
    color: #6E6E6E;
    letter-spacing: 0;
  }
}

.mobile-map-buttons {
  display: none;
}

.vue-map-container {
  height: calc(100vh - 240px) !important;
  grid-area: map;
}

.overlayer {
  margin-top: -140px;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background: #FFFFFF;;
  z-index: 2;
}

@media (max-width: 768px) {

  .content {
    grid-template-columns: 100%;
    grid-template-areas:
    "hotel-list";
    height: calc(100vh - 140px);
    position: relative;

    &.mobile-map-view {
      grid-template-areas: "map";

      & .list-container {
        display: none;
      }

      & .map-container {
        display: initial;
      }
    }

    .map-container {
      display: none;
    }

    .mobile-map-buttons {
      display: initial;
      position: absolute;
      right: 15px;
      height: 45px;
      width: 45px;
      z-index: 5;

      button {
        border: none;
        padding: 0;
        margin: 0;
        display: block;
        height: 100%;
        width: 100%;
        outline: none;
        background: white;
        border-radius: 5px;
        box-shadow: 0 2px 11px 0 rgba(0,0,0,0.15);
        line-height: 40px;

        img {
          vertical-align: middle;
          display: inline-block;
        }
      }
    }

  }

  .list-container {
    padding-top: 20px;
    margin: 0 10px 20px 10px;

    ul {
      padding: 0;
      height:  calc(100vh - 180px) !important;

      .hotel-card {
        margin-right: 0;
      }

      .show-more {
        margin-right: 0;
      }
    }

    .no-result {

      p {
        font-size: 18px;
      }

      a {
        font-size: 14px;
      }
    }
  }

}

/*
  button position according to height of the screen

  iPhoneX : 812px
*/

@media (min-height: 400px) {
  .content .mobile-map-buttons {
    top: 80%
  }
}

@media (min-height: 812px) {
  .content .mobile-map-buttons {
    top: 70%
  }
}

// Animations

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slide-in-enter-active {
  animation: slide-up 1.5s forwards;

}

@keyframes slide-up {
  from {
    transform: translateY(150px);
  }
  to {
    transform: translateY(0);
  }
}

.mobile-filters {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .filters {
    display: none !important;
  }

  .mobile-filters {
    display: flex !important;
  }
}

</style>
